export default [
  {
    name: 'Embrace',
    id: 'embrace',
  },
  {
    name: 'HealthyPaws',
    id: 'healthypaws',
  },
  {
    name: 'Nationwide',
    id: 'nationwide',
  },
  {
    name: 'Trupanion',
    id: 'trupanion',
  },
  {
    name: 'PetPlan',
    id: 'petplan',
  },
  {
    name: 'Figo',
    id: 'figo',
  },
  {
    name: 'PetsBest',
    id: 'petsbest',
  },
  {
    name: 'AKC Pet Insurance',
    id: 'akc',
  },
];
