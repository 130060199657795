import clsx from 'clsx';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';

import {
    Box, Button, Container, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemText, Menu,
    MenuItem, MenuList, Popover
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';

import providers from '../../config/providers';
import BreezyLogo from '../assets/BreezyLogo';
import FacebookIcon from '../assets/FacebookIcon';
import InstagramIcon from '../assets/InstagramIcon';
import TwitterIcon from '../assets/TwitterIcon';

interface Props {
  className?: string;
  logoMobileSize?: number;
  logoDesktopSize?: number;
  style?: CSSProperties;
  scaleDesktopLogo?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  nav: {
    padding: '10px 0',
  },
  stickyNav: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1201,
    backgroundColor: 'white',
    boxShadow: '0px 4px 10px rgba(216, 216, 216, 0.25)',
  },
  button: {
    borderRadius: 50,
    fontWeight: 'bold',
  },
  drawerPaper: {
    width: '100%',
  },
  iconLink: {
    color: '#000000',
    marginRight: 32,
  },
  desktopLogo: {
    transition: 'all .2s ease-in-out',
    transformOrigin: 'center left',
  },
}));

const DrawerContent = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
    >
      <div>
        <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 24 }}>
          <span
            style={{
              fontSize: '16px',
              lineHeight: '19px',
              color: 'rgba(0,0,0,0.6)',
            }}
          >
            Learn more about each pet insurance company.
          </span>
        </div>
        <List>
          {providers.map((provider) => (
            <Link key={provider.id} href={`/providers/${provider.id}`} passHref>
              <ListItem button component="a">
                <ListItemText
                  primary={provider.name}
                  primaryTypographyProps={{
                    style: { fontSize: '18px', color: '#000000' },
                  }}
                />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />

        <ListItem button href="mailto:hi@sobreezy.com" component="a">
          <ListItemText
            primary="Contact Us: hi@sobreezy.com"
            primaryTypographyProps={{
              style: { fontSize: '18px', color: '#000000' },
            }}
          />
        </ListItem>
      </div>
      <div style={{ padding: 24 }}>
        <a
          href="https://facebook.com/thebreezyapp/"
          target="_blank"
          rel="noopener"
          className={classes.iconLink}
        >
          <FacebookIcon fontSize="large" />
        </a>
        <a
          href="https://twitter.com/thebreezyapp/"
          target="_blank"
          rel="noopener"
          className={classes.iconLink}
        >
          <TwitterIcon fontSize="large" />
        </a>
        <a
          href="https://instagram.com/thebreezyapp/"
          target="_blank"
          rel="noopener"
          className={classes.iconLink}
        >
          <InstagramIcon fontSize="large" />
        </a>
      </div>
    </Box>
  );
};

const Nav = (props: Props) => {
  const {
    className,
    logoMobileSize = 33,
    logoDesktopSize = 37,
    style,
    scaleDesktopLogo = false,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSticky, setSticky] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const placeholderRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleScroll = () => {
    if (placeholderRef.current) {
      const shouldBeSticky =
        placeholderRef.current.getBoundingClientRect().top < 0;

      setSticky(shouldBeSticky);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={className}
      style={{
        ...style,
        position: 'relative',
        height: navRef.current?.clientHeight,
      }}
    >
      <div ref={placeholderRef} />
      <nav
        ref={navRef}
        className={clsx(
          classes.nav,
          isSticky || mobileOpen ? classes.stickyNav : '',
          isSticky || mobileOpen ? 'mui-fixed' : '',
        )}
      >
        <Container>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Hidden mdUp implementation="css">
                <Box display="flex" alignItems="center">
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    style={{ marginLeft: -25, marginRight: 5 }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    hideBackdrop
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    transitionDuration={{
                      enter: 300,
                      exit: 0,
                    }}
                    SlideProps={{ direction: 'down' }}
                    style={{ zIndex: 1200 }}
                  >
                    <div style={{ height: 68 }} />
                    <DrawerContent />
                  </Drawer>
                  <Link href="/">
                    <a style={{ textDecoration: 'none' }}>
                      <BreezyLogo
                        id="breezylogo-mobile"
                        size={logoMobileSize}
                      />
                    </a>
                  </Link>
                </Box>
              </Hidden>
              <Hidden smDown implementation="css">
                <Link href="/">
                  <a style={{ textDecoration: 'none' }}>
                    <BreezyLogo
                      id="breezylogo-desktop"
                      className={classes.desktopLogo}
                      size={logoDesktopSize}
                      style={{
                        transform:
                          isSticky && scaleDesktopLogo
                            ? 'scale(0.8)'
                            : 'scale(1)',
                      }}
                    />
                  </a>
                </Link>
              </Hidden>
            </div>

            <Box display="flex" alignItems="center">
              <Hidden smDown implementation="css">
                <Button
                  aria-controls="pet-insurance-companies-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '22px',
                  }}
                >
                  Pet Insurance Companies
                </Button>
                <Popover
                  id="pet-insurance-companies-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  disablePortal
                >
                  <MenuList>
                    <div
                      style={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          lineHeight: '19px',
                          color: 'rgba(0,0,0,0.6)',
                        }}
                      >
                        Learn more about each pet insurance company.
                      </span>
                    </div>
                    {providers.map((provider) => (
                      <Link
                        key={provider.id}
                        href={`/providers/${provider.id}`}
                        passHref
                      >
                        <MenuItem component="a">{provider.name}</MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </Popover>
              </Hidden>
              <Hidden smDown implementation="css">
                <Link passHref href="/quote">
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    style={{ marginLeft: 40, height: 48, width: 160 }}
                  >
                    Get Free Quote
                  </Button>
                </Link>
              </Hidden>
              <Hidden mdUp implementation="css">
                <Link passHref href="/quote">
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    Quote
                  </Button>
                </Link>
              </Hidden>
            </Box>
          </Box>
        </Container>
      </nav>
    </div>
  );
};

export default Nav;
