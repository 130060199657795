import Link from 'next/link';
import React, { CSSProperties, FC } from 'react';

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import providers from '../config/providers';
import FacebookIcon from './assets/FacebookIcon';
import InstagramIcon from './assets/InstagramIcon';
import TwitterIcon from './assets/TwitterIcon';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '40px 0',
    backgroundColor: '#f4d87d',
    display: 'flex',
    alignItems: 'center',
  },
  footerText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '155%',
    color: '#000000',
    textDecoration: 'none',
  },
  iconLink: {
    color: '#000000',
    marginRight: 20,
  },
  sectionTitle: {
    fontSize: '24px',
    lineHeight: '29px',
    margin: 0,
    marginBottom: 16,
  },
  itemBottomMargin: {
    marginBottom: 8,
  },
}));

interface Props {
  style?: CSSProperties;
}

const Footer = React.forwardRef<HTMLDivElement, Props>(({ style }, ref) => {
  const classes = useStyles({});

  return (
    <footer ref={ref} className={classes.footer} style={style}>
      <Container>
        <Grid container spacing={3}>
          <Grid component="section" item xs={12} md={3}>
            <h4 className={classes.sectionTitle}>Contact</h4>
            <div style={{ marginBottom: 16 }}>
              <a href="mailto:hi@sobreezy.com" className={classes.footerText}>
                hi@sobreezy.com
              </a>
            </div>
            <div>
              <a
                href="https://facebook.com/thebreezyapp/"
                target="_blank"
                rel="noopener"
                className={classes.iconLink}
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/thebreezyapp/"
                target="_blank"
                rel="noopener"
                className={classes.iconLink}
              >
                <TwitterIcon />
              </a>
              <a
                href="https://instagram.com/thebreezyapp/"
                target="_blank"
                rel="noopener"
                className={classes.iconLink}
              >
                <InstagramIcon />
              </a>
            </div>
          </Grid>
          <Grid component="section" item xs={12} md={3}>
            <h4 className={classes.sectionTitle}>Legal</h4>
            <div className={classes.itemBottomMargin}>
              <Link passHref href="/privacy">
                <a className={classes.footerText}>Privacy Policy</a>
              </Link>
            </div>
            <div className={classes.itemBottomMargin}>
              <Link passHref href="/terms">
                <a className={classes.footerText}>Terms of Service</a>
              </Link>
            </div>
          </Grid>
          <Grid component="section" item xs={12} md>
            <h4 className={classes.sectionTitle}>Pet Insurance Companies</h4>
            <Grid container>
              {providers.map((provider) => (
                <Grid
                  key={provider.id}
                  item
                  xs={4}
                  className={classes.itemBottomMargin}
                >
                  <Link
                    key={provider.id}
                    passHref
                    href={`/providers/${provider.id}`}
                  >
                    <a className={classes.footerText}>{provider.name}</a>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
});

export default Footer;
